import React from "react";
import s from "./Pill.module.scss";
import cn from "classnames";

type PillProps = {
  children: string | number;
  className?: string;
};

const Pill = ({ children, className }: PillProps) => (
  <span className={cn(className, s.pill)}>{children}</span>
);

export default Pill;

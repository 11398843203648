/* eslint-disable no-console */
import { useEffect, useState } from "react";

export const useIsExternalLink = (href?: string) => {
  const [isExternalLink, setIsExternalLink] = useState(false);
  const [shouldNofollow, setShouldNofollow] = useState(true);

  useEffect(() => {
    const checkExternalLink = () => {
      if (!href) {
        setIsExternalLink(false);
        setShouldNofollow(true);
        return;
      }

      if (
        href.includes("mailto:") ||
        (href.includes("@") && !href.includes("/"))
      ) {
        setIsExternalLink(true);
        setShouldNofollow(true);
        return;
      }

      if (href.startsWith("/")) {
        setIsExternalLink(false);
        setShouldNofollow(true);
        return;
      }

      try {
        const url = href.startsWith("http") ? href : `https://${href}`;
        const urlObj = new URL(url);
        const { hostname } = urlObj;
        const currentHost = window.location.host;

        const topLevelDomain = currentHost.split(".").slice(-2).join(".");
        const isDifferentTopLevelDomain =
          topLevelDomain !== hostname.split(".").slice(-2).join(".");

        const isExternal =
          hostname !== currentHost || isDifferentTopLevelDomain;
        setIsExternalLink(isExternal);

        switch (true) {
          case currentHost.includes(".tobii.com"):
            setShouldNofollow(false);
            break;

          case currentHost === "www.tobii.cn" && isExternal:
          case currentHost === "www.tobii.com" &&
            (isExternal || hostname.includes(".tobii.com")):
            setShouldNofollow(true);
            break;

          default:
            setShouldNofollow(false);
        }
      } catch (err) {
        setIsExternalLink(false);
        setShouldNofollow(true);
      }
    };

    checkExternalLink();
  }, [href]);

  return { isExternalLink, shouldNofollow };
};

import { storyblokAxios } from "../storyblokAxios";
import { Block, Story } from "../types/story";

//https://www.storyblok.com/docs/api/content-delivery/v2#core-resources/stories/retrieve-multiple-stories
type Response<T extends Block> = {
  stories: Story<T>[];
};

type Params = { [index: string]: string | number };

export const getStories = async <T extends Block>(
  params?: Params,
  options?: Parameters<typeof storyblokAxios.get>[1]
): Promise<Story<T>[]> => {
  const per_page = 25;

  const { data, headers } = await fetchStories();
  const totalPages = Math.ceil(parseInt(headers.total) / per_page);
  if (totalPages < 2) return data.stories;

  // If there is more than one page, fetch the rest of them
  const remainingPagesPromises = new Array(totalPages - 1)
    .fill(null)
    .map((_, i) => fetchStories(i + 2));
  const remainingPages = await Promise.all(remainingPagesPromises);
  const remainingStories = remainingPages.flatMap(({ data }) => data.stories);

  return [...data.stories, ...remainingStories];

  async function fetchStories(page?: number) {
    return await storyblokAxios.get<Response<T>>(`stories`, {
      // id: `story-${JSON.stringify(params)}`,
      ...options,
      params: {
        ...params,
        per_page,
        page,
      },
    });
  }
};

import { getSpace, getSpaceUrl } from "./endpoints/getSpace";
import axios, { AxiosInstance } from "axios";
import { setupCache } from "axios-cache-interceptor";
import { addSeconds, isAfter } from "date-fns";
import axiosRetry, {
  exponentialDelay,
  isNetworkOrIdempotentRequestError,
} from "axios-retry";

const token = process.env.NEXT_PUBLIC_STORYBLOK_TOKEN;
if (typeof token === "undefined") throw new Error("Storybloktoken missing");

const IS_PREVIEW_MODE =
  process.env.NEXT_PUBLIC_PREVIEW_MODE?.toUpperCase() === "TRUE";
const instance = axios.create({
  baseURL: "https://api.storyblok.com/v2/cdn",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  params: {
    token,
    version: IS_PREVIEW_MODE ? "draft" : "published",
  },
});

let cv: number;
let cvUpdated: Date;

instance.interceptors.request.use(async (config) => {
  if (
    config.url !== getSpaceUrl &&
    (!cv || isAfter(new Date(), addSeconds(cvUpdated, 5)))
  ) {
    const { data } = await getSpace();
    cv = data.space.version;
    cvUpdated = new Date();
  }
  config.params["cv"] = cv;
  return config;
});

export const storyblokAxios = setupCache(instance, {
  ttl: IS_PREVIEW_MODE ? 1 * 1000 : 10 * 1000,
});

axiosRetry(storyblokAxios as unknown as AxiosInstance, {
  retries: 3,
  retryCondition: (error) =>
    error.response?.status === 429 || isNetworkOrIdempotentRequestError(error),
  retryDelay: exponentialDelay,
});

import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import FourOhFourUi from "../ui/FourOfFour";
import { getStory } from "../lib/storyblok/endpoints/getStory";
import { GlobalStory } from "../lib/storyblok/types/stories/globalStory";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import FourOhFourImage from "../public/images/404.jpg";
import Image from "next/image";

const FourOhFour = ({ locale }) => {
  const router = useRouter();
  const currentUrl = `${router.basePath}${router.asPath}`;

  const endpointPrefix = currentUrl.includes("/investor") ? "investor" : "main";

  const [globalStory, setGlobalStory] = useState<GlobalStory | null>(null);
  useEffect(() => {
    const fetchGlobalStory = async () => {
      try {
        const { story } = await getStory<GlobalStory>(
          `${locale}/${endpointPrefix}/global`
        );

        // @ts-expect-error: Type assertion issue with `story` - needs further investigation
        setGlobalStory(story);
      } catch (error) {
        // eslint-disable-next-line
        console.error("Error fetching global story:", error);
      }
    };

    fetchGlobalStory();
  }, [locale, endpointPrefix]);

  const newsLinkRaw =
    globalStory?.content?.FourOhFour?.[0]?.newsLink?.cached_url;

  const newsLink = newsLinkRaw?.startsWith(`${locale}/`)
    ? `/${newsLinkRaw}`
    : `/${locale}/${newsLinkRaw}`;

  const newsTitle = globalStory?.content?.FourOhFour?.[0]?.newsTitle;
  const isCorporate =
    globalStory?.content?.FourOhFour?.[0]?.isCorporate ?? false;

  const localeLinks = {
    sv: {
      home: "/sv",
      search: "/sv/sok",
      contact: "/sv/kontakt",
    },
    zh: {
      home: "/zh",
      search: "/zh/search",
      contact: "/zh/contact",
    },
    ja: {
      home: "/ja",
      search: "/ja/search",
      contact: "/ja/contact",
    },
    default: {
      home: "/",
      search: "/search",
      contact: "/contact",
    },
  };

  const links = localeLinks[locale] || localeLinks.default;

  return (
    <FourOhFourUi
      image={
        <Image src={FourOhFourImage} sizes="960px" quality={100} alt="404" />
      }
      isCorporate={isCorporate}
      locale={locale}
      goHomeLink={links.home}
      searchLink={links.search}
      newsLink={newsLink}
      newsTitle={newsTitle}
      contactLink={links.contact}
      socialMediaCustomClassName="social404"
    />
  );
};

export const getStaticProps = async ({ locale }) => {
  const props = {
    locale,
    ...(await serverSideTranslations(locale, ["common"])),
  };

  return {
    props: {
      ...props,
    },
  };
};

export default FourOhFour;

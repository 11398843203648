import React from "react";
import s from "../Footer.module.scss";
import cn from "classnames";

const SoMeLinkList = ({ children, customClassName }) => {
  const listClassName = cn(s.soMeLinkList, customClassName);
  return <ul className={listClassName}>{children}</ul>;
};

export default SoMeLinkList;

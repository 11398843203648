import { storyblokAxios } from "../storyblokAxios";
import { Link as LinkRel } from "../types/link";
import { Block, Story } from "../types/story";
import { getLinks } from "./getLinks";
import { getStories } from "./getStories";

export type Link = {
  name: string;
  id: number;
  uuid: Uuid;
  slug: string;
  full_slug: string;
  url: string; // <-- URL that can be used for links and will stay up2date
};

//https://www.storyblok.com/docs/api/content-delivery/v2#core-resources/stories/retrieve-one-story
type Response<T extends Block> = {
  story: Story<T>;
  rels?: Story[];
  rel_uuids?: Uuid[];
  links: Link[] | LinkRel[];
  link_uuids?: Story[];
  cv: string;
};

export const getStory = async <T extends Block = Block>(id: string) => {
  const { data } = await storyblokAxios.get<Response<T>>(`stories/${id}`, {
    validateStatus: (status) => status < 400 || status === 404,
    params: {
      resolve_links: "url",
      resolve_relations: [
        "authors",
        "collaborators",
        "speakersv2",
        "contacts",
        "contentGrid.items",
        "contentGridSlider.items",
        "featuredContentGrid.items",
        "featuredContentGridItem.item",
        "event",
        "featuredEvent",
        "featuredArticle",
        "featuredResource",
        "editorsPick",
        "mostPopular",
        "highlight",
      ].join(","),
    },
  });

  //rel_uuids is an array of the uuids of all the related story objects if the resolve_relations parameter is in use
  //and if the limit of resolvable relations is hit
  if (data.rel_uuids) {
    data.rels = await getStories({
      by_uuids: data.rel_uuids.join(","),
    });
  }

  //link_uuids is an array of the uuids of all the related story objects if the resolve_links parameter is in use
  //and if the limit of resolvable links is hit.
  if (data.link_uuids) {
    data.links = await getLinks({
      by_uuids: data.link_uuids.join(","),
    });
  }

  return data;
};

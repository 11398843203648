import React from "react";
import SoMeLinkList from "../Footer/components/SoMeLinkList";
import SoMeLink from "../Footer/components/SoMeLink";
import { IconType } from "../Icon/Icon";

type Props = {
  isCorporate: boolean;
  locale: string;
  customClassName?: string;
};

const socialLinks: { icon: IconType; href: string }[] = [
  { icon: "facebook", href: "https://www.facebook.com/TobiiAB/" },
  { icon: "twitter", href: "https://twitter.com/TobiiTechnology" },
  { icon: "instagram", href: "https://www.instagram.com/lifeattobii/" },
  { icon: "linkedin", href: "https://www.linkedin.com/company/tobii/" },
  { icon: "youtube", href: "https://www.youtube.com/TobiiPro" },
  { icon: "weibo", href: "https://weibo.com/tobiieyetracking" },
  { icon: "zhihu", href: "https://www.zhihu.com/people/tobiiprozhong-guo" },
  { icon: "bilibili", href: "https://space.bilibili.com/670400132" },
  { icon: "line", href: "https://lin.ee/QptLD8T" },
];

const socialMediaConfig = {
  false_en: ["facebook", "twitter", "youtube", "linkedin", "instagram"],
  false_ja: ["facebook", "twitter", "youtube", "linkedin", "instagram", "line"],
  false_sv: ["facebook", "twitter", "youtube", "linkedin", "instagram"],
  false_zh: ["weibo", "zhihu", "bilibili"],
  true_en: ["facebook", "twitter", "youtube", "linkedin", "instagram"],
  true_sv: ["facebook", "twitter", "youtube", "linkedin", "instagram"],
};

const SocialMediaLinks = ({ isCorporate, locale, customClassName }: Props) => {
  const key = `${isCorporate}_${locale}`;
  const iconsToShow = socialMediaConfig[key] || [];

  const filteredLinks = socialLinks.filter((link) =>
    iconsToShow.includes(link.icon)
  );

  return (
    <div>
      <SoMeLinkList customClassName={customClassName}>
        {filteredLinks.map((link, index) => (
          <SoMeLink key={index} icon={link.icon} href={link.href} />
        ))}
      </SoMeLinkList>
    </div>
  );
};

export default SocialMediaLinks;

import { storyblokAxios } from "../storyblokAxios";
import { Link } from "../types/link";

type Response = { links: { [key: Uuid]: Link } };

type Params = { [index: string]: string };

export const getLinks = async (params?: Params) => {
  const { data } = await storyblokAxios.get<Response>("links/", {
    params,
  });

  return Object.values(data.links);
};

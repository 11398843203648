import { storyblokAxios } from "../storyblokAxios";

type Response = {
  space: {
    id: number;
    name: string;
    domain: string;
    version: number;
  };
};

export const getSpaceUrl = `spaces/me`;
export const getSpace = async () =>
  await storyblokAxios.get<Response>(getSpaceUrl);
